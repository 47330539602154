'use client';

import { Workspace, WorkspaceAccess } from '@prisma/client';
import { useMutation } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';
import { toast } from 'react-toastify';

import { CreateSubWorkspaceAPIResponse } from '@/app/api/workspace/sub/route';
import SuccessToast from '@/components/toasts/success-toast.component';
import WarningToast from '@/components/toasts/warning-toast.component';

type CreateSubWorkspaceParams = {
  name: string;
  parentId: string;
};

export function useCreateSubWorkspaceMutation() {
  const { update } = useSession();

  return useMutation({
    mutationFn: async ({ name, parentId }: CreateSubWorkspaceParams) => {
      const response = await fetch('/api/workspace/sub', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name, parentId }),
      });

      const result: CreateSubWorkspaceAPIResponse = await response.json();

      if (result.status === 'error') {
        toast(
          <WarningToast
            title="Failed to create workspace"
            detail={result.message}
          />,
        );
        return null;
      }

      // Update session with new workspace
      await update({
        workspace: result.workspace,
      });

      toast(
        <SuccessToast
          title="Success"
          detail="Workspace created successfully"
        />,
      );
      return result;
    },
  });
}
