import { Chatbot } from '@prisma/client';
import set from 'lodash/set';
import posthog from 'posthog-js';

import { TranslationSettingsFormData } from '@/app/(dashboard)/aibot/[chatbotId]/(layout)/customize/form';
import { CustomizeKeys } from '@/app/(dashboard)/aibot/[chatbotId]/(layout)/customize/types';
import { SettingKeys } from '@/app/(dashboard)/aibot/[chatbotId]/(layout)/settings/types';
import { PostHogEventProperties, PostHogEvents } from '@/types';

import { getChangedKeysAndValues } from './object';

export function sendClientSidePosthogEvent(
  event: PostHogEvents,
  properties: PostHogEventProperties,
) {
  posthog.capture(event, {
    ...properties,
    stage: process.env.NEXT_PUBLIC_ENVIRONMENT,
  });
}

type ChatbotSettings = Pick<
  Chatbot,
  | 'openAIApiKey'
  | 'isSatisfactionSurveyEnabled'
  | 'isLiveChatEnabled'
  | 'liveChatRoutingMessage'
  | 'isIntentRoutingEnabled'
>;

type PosthogCategories =
  | SettingKeys
  | CustomizeKeys
  | keyof ChatbotSettings
  | keyof TranslationSettingsFormData;

type PosthogCategoryMap = {
  [K in PosthogCategories]: PostHogEvents;
};

const POSTHOG_SETTINGS_CATEGORIES: Partial<PosthogCategoryMap> = {
  aiName: 'general_settings_updated',
  company: 'general_settings_updated',
  basePrompt: 'general_settings_updated',
  gptModel: 'general_settings_updated',
  openAIApiKey: 'general_settings_updated',
  isSatisfactionSurveyEnabled: 'ai_answer_settings_updated',
  isLearnMoreEnabled: 'ai_answer_settings_updated',
  isImageSharingEnabled: 'ai_answer_settings_updated',
  customDoNotKnowMessage: 'ai_answer_settings_updated',
  followUpQuestionEnabled: 'ai_answer_settings_updated',
  isRestricted: 'ai_answer_settings_updated',
  isLiveChatEnabled: 'live_chat_settings_updated',
  liveChatRoutingMessage: 'live_chat_settings_updated',
  isIntentRoutingEnabled: 'live_chat_settings_updated',
  'collectUserData.status': 'collect_user_data_updated',
  'collectUserData.after': 'collect_user_data_updated',
  'collectUserData.email.status': 'collect_user_data_updated',
  'collectUserData.email.required': 'collect_user_data_updated',
  'collectUserData.phone.status': 'collect_user_data_updated',
  'collectUserData.phone.required': 'collect_user_data_updated',
  'collectUserData.phone.countryCode': 'collect_user_data_updated',
  'collectUserData.fullName.status': 'collect_user_data_updated',
  'collectUserData.fullName.required': 'collect_user_data_updated',
  temperature: 'advanced_configuration_updated',
  maxTokens: 'advanced_configuration_updated',
  maxInputLength: 'advanced_configuration_updated',
  'limiter.alertText': 'advanced_configuration_updated',
  'limiter.limit': 'advanced_configuration_updated',
  'limiter.window': 'advanced_configuration_updated',
  'humanRouting.after': 'advanced_configuration_updated',
  'humanRouting.platforms': 'advanced_configuration_updated',
  'humanRouting.status': 'advanced_configuration_updated',
  'theme.align': 'widget_settings_updated',
  'theme.hideTeaserMessage': 'widget_settings_updated',
  teaserMessage: 'widget_settings_updated',
  'theme.humanAgentPreviewText': 'widget_settings_updated',
  'schedule.status': 'widget_settings_updated',
  'schedule.timezone': 'widget_settings_updated',
  'schedule.dates': 'widget_settings_updated',
  'fullPage.header': 'widget_settings_updated',
  'fullPage.shortMessage': 'widget_settings_updated',
  'fullPage.slug': 'widget_settings_updated',
  'fullPage.showHumanAvatar': 'widget_settings_updated',
  'fullPage.indexable': 'widget_settings_updated',
  'fullPage.metaTitle': 'widget_settings_updated',
  'fullPage.metaDescription': 'widget_settings_updated',
  'fullPage.theme': 'widget_settings_updated',
  'theme.color': 'branding_settings_updated',
  'theme.headerLogo': 'branding_settings_updated',
  'theme.aiAvatar': 'branding_settings_updated',
  'theme.removeBranding': 'branding_settings_updated',
  'theme.brandLogo': 'branding_settings_updated',
  customCSS: 'branding_settings_updated',
  initialMessage: 'initial_settings_updated',
  removeInitialMessage: 'initial_settings_updated',
  detailedSuggestedMessage: 'initial_settings_updated',
  language: 'localization_changed',
  slack: 'slack_integration_saved',
};

export function getChangedValuesAndPosthogCategories<T extends object>({
  defaultObj,
  newObj,
}: {
  defaultObj: T;
  newObj: Partial<T>;
}) {
  const changedKeysAndValues = getChangedKeysAndValues(defaultObj, newObj);

  const categories = changedKeysAndValues.reduce(
    (acc, { key, newValue }) => {
      const category = findCategory(key as string);

      if (category) {
        const existingCategory = acc[category] || {};
        set(existingCategory, key, newValue);
        acc[category] = existingCategory;
      }

      return acc;
    },
    {} as Record<PostHogEvents, Record<string, any>>,
  );

  return Object.entries(categories).map(([key, changes]) => ({
    key: key as PostHogEvents,
    changes,
  }));
}

function findCategory(key: string): PostHogEvents | undefined {
  if (POSTHOG_SETTINGS_CATEGORIES[key]) {
    return POSTHOG_SETTINGS_CATEGORIES[key];
  }

  const matchingPrefix = Object.keys(POSTHOG_SETTINGS_CATEGORIES)
    .filter((categoryKey) => key.startsWith(categoryKey))
    .sort((a, b) => b.length - a.length)[0];

  return matchingPrefix
    ? POSTHOG_SETTINGS_CATEGORIES[matchingPrefix]
    : undefined;
}
